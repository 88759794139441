const BingoCard = ({
  remit,
  handleRemitClick,
  completed,
  index,
  matchedRemitId,
  playerName,
  startGame,
  clickedRemitId, // The clicked card's ID to determine feedback
}) => {
  // Generate the appropriate image source based on remit type
  const getSrc = (remit) => {
    switch (remit.type) {
      case "trophy":
        return `https://playfootball.games/media/trophies/${remit.fileName}`;
      case "team":
        return "https://cdn.sportmonks.com/images/soccer/teams/19/83.png";
      case "country":
        return `https://playfootball.games/media/nations/${remit.id}.svg`;
      case "manager":
        return `https://playfootball.games/media/managers/${remit.id}.png`;
      case "teammate":
        return `https://playfootball.games/media/teammates/${remit.id}.webp`;
      case "league":
        return `https://playfootball.games/media/leagues/${remit.id}.webp`;
      default:
        return "/fallback-image.png"; // Fallback for unknown types
    }
  };

  // Generate display text based on remit type
  const getDisplayText = () => {
    switch (remit.type) {
      case "trophy":
        return remit.displayName || "Unknown";
      case "league":
        return remit.displayName || "Unknown";
      case "manager":
        return `Managed by ${remit.displayName || "Unknown"}`;
      case "teammate":
        return `Teammate ${remit.displayName || "Unknown"}`;
      default:
        return remit.shortCode || "N/A";
    }
  };

  const displayText = getDisplayText();
  const isLongText = displayText.length > 3;

  const isMatched = remit.id === matchedRemitId;

  const isMatchedViaWildCard = completed.some(
    (entry) => entry.remitId === remit.id && entry.viaWildCard
  );

  const isClicked = remit.id === clickedRemitId;

  return (
    <div
      className={`[&:nth-child(8n+1)]:bg-[#3C3147] [&:nth-child(8n+3)]:bg-[#3C3147] [&:nth-child(8n+6)]:bg-[#3C3147] [&:nth-child(8n)]:bg-[#3C3147] relative mx-auto h-full w-full flex flex-col items-center justify-center hover:opacity-80 ${
        startGame && !isMatched ? "hover:cursor-pointer" : "cursor-default z-50"
      } `}
      onClick={() => {
        if (startGame && !isMatched) {
          handleRemitClick(remit);
        }
      }}
    >
      <div
        className={`absolute inset-2 flex flex-col ${
          isMatched ? "text-white" : "text-black"
        } items-center justify-center`}
      >
        {isClicked && (
          <div
            key={index}
            className={`absolute flex justify-center w-full h-full rounded-lg bg-red-500 fillColor`}
          >
            <div className="absolute bottom-0 max-w-full px-1 sm:pb-1 text-center font-bebas-neue text-black text-sm sm:text-base truncate"></div>
          </div>
        )}
        {isMatched && (
          <div
            key={index}
            className={`${
              isMatchedViaWildCard ? "bg-yellow-500" : "bg-[#ceff27]"
            } absolute flex justify-center w-full h-full rounded-lg fillColor`}
          >
            <div className="absolute bottom-0 max-w-full px-1 sm:pb-1 text-center font-bebas-neue text-black text-sm sm:text-base truncate">
              {playerName?.f || playerName?.g}
            </div>
          </div>
        )}
        <img
          width="40"
          height="40"
          className="filter-shadow relative w-[40px] sm:w-[50px]"
          src={getSrc(remit)}
          alt={remit.type || "Bingo Card Image"}
        />

        <div
          className={`mt-1 text-white text-center ${
            isLongText ? "leading-none text-base" : "text-xl"
          } font-bebas-neue`}
          translate="no"
        >
          {displayText}
        </div>
      </div>
    </div>
  );
};

export default BingoCard;
