import React from "react";
import WildCardTip from "./WildCardTip";

const WildCard = ({ setIsShowToolTip, isShowToolTip, handleWildCard }) => {
  return (
    <div className="self-start">
      <div className="mt-1 flex items-center">
        <button
          onClick={handleWildCard}
          className="relative text-sm text-black rounded-md border border-transparent text-center font-bold shadow-sm py-1 px-2 sm:text-base focus:outline-none focus:ring-2 focus:ring-offset-2 bg-[#ceff27] hover:bg-[#ceff27]/90 focus:ring-[#ceff27]"
        >
          Play Wildcard
        </button>
        <button
          className="p-1"
          onClick={() => setIsShowToolTip(!isShowToolTip)}
        >
          {!isShowToolTip ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="text-white"
              width="26"
              height="26"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="text-white"
              width="26"
              height="26"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              ></path>
            </svg>
          )}
        </button>
      </div>
      {isShowToolTip && <WildCardTip />}
    </div>
  );
};

export default WildCard;
