import React from "react";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
const TimerNoTimer = ({ handleTimer }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full flex items-center justify-center gap-2">
        <button
          onClick={() => handleTimer(true)}
          className="w-[120px] text-black flex items-center justify-center relative rounded-md border border-transparent text-center font-bold shadow-sm py-1.5 px-2 focus:outline-none focus:ring-2 focus:ring-offset-2 bg-[#ceff27] hover:bg-[#ceff27]/90 focus:ring-[#ceff27]"
        >
          <TimerOutlinedIcon />
          Timer
        </button>
        <span className="text-white font-bold">OR</span>
        <button
          onClick={() => handleTimer(false)}
          className="w-[120px] text-black flex items-center justify-center relative rounded-md border border-transparent text-center font-bold shadow-sm py-1.5 px-2 focus:outline-none focus:ring-2 focus:ring-offset-2 bg-[#ceff27] hover:bg-[#ceff27]/90 focus:ring-[#ceff27]"
        >
          <TimerOffOutlinedIcon />
          No Timer
        </button>
      </div>
    </div>
  );
};

export default TimerNoTimer;
