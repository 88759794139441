import React from "react";

const WildCardTip = () => {
  return (
    <div class="absolute z-[1] shadow py-1 px-2 mt-2 mr-2 bg-indigo-600 rounded text-xs text-white max-w-[350px]">
      Use your wildcard to complete as many categories on the bingo card as
      Joaquín covers. Can only be played once so use it wisely.
    </div>
  );
};

export default WildCardTip;
