import React from "react";
import "./App.css";

import Game from "./component/Game/Game";

function App() {
  return (
    <div className="sm:px-2">
      <Game />
    </div>
  );
}

export default App;
