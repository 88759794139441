import React, { useState, useEffect } from "react";

import WildCard from "./WildCard";

import SkipForwardCom from "./SkipForwardCom";

const NoTimerHeader = ({
  currentPlayer,
  onSkip,
  playersLeft,
  handleWildCard,
  isWildCardUsed,
  playerNumber,
  clickedRemitId,
  gameResult,
}) => {
  const [isShowToolTip, setIsShowToolTip] = useState(false);
  const [nameKey, setNameKey] = useState(0);

  useEffect(() => {
    setNameKey((prev) => prev + 1);
  }, [currentPlayer]);

  return (
    <div className="bg-indigo-800 px-4 py-3 sm:rounded-t-lg flex justify-between gap-4">
      {gameResult && (
        <div className="font-bebas-neue text-white text-3xl">{gameResult}</div>
      )}
      {!gameResult && (
        <div className="flex items-center gap-3 sm:gap-4">
          <div className="self-start bg-white rounded-full filter-shadow text-center">
            <div className="w-[50px] h-[50px] flex items-center justify-center">
              <div className="flex items-center justify-center rounded-full">
                <div className="text-3xl mt-0.5 sm:mt-1 font-bebas-neue text-black">
                  {playerNumber}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="relative flex items-center text-white font-bebas-neue">
              <div className="flex flex-col justify-center">
                <div
                  key={`${nameKey}-first`}
                  translate="no"
                  className="slide-in sm:text-xl leading-none sm:leading-none"
                >
                  {currentPlayer?.f}
                </div>
                <div
                  key={`${nameKey}-last`}
                  translate="no"
                  className="slide-in text-3xl leading-none sm:leading-none"
                >
                  {currentPlayer?.g}
                </div>
              </div>
            </div>
            {!isWildCardUsed && (
              <WildCard
                setIsShowToolTip={setIsShowToolTip}
                isShowToolTip={isShowToolTip}
                handleWildCard={handleWildCard}
              />
            )}
          </div>
        </div>
      )}
      {playersLeft > 1 && !gameResult && (
        <SkipForwardCom
          clickedRemitId={clickedRemitId}
          onSkip={onSkip}
          playersLeft={playersLeft}
        />
      )}
    </div>
  );
};

export default NoTimerHeader;
