import React, { useState } from "react";
import SkipForwardCom from "./SkipForwardCom";
import WildCard from "./WildCard";
const TimerHeader = ({
  playerName,
  onSkip,

  playersLeft,
  timer,
  handleWildCard,
  isWildCardUsed,
  clickedRemitId,
  gameResult,
}) => {
  const [isShowToolTip, setIsShowToolTip] = useState(false);

  function getColorBasedOnTime(timer) {
    if (timer > 6) {
      return "green";
    } else if (timer > 3) {
      return "orange";
    } else {
      return "red";
    }
  }
  function calculateDashOffset(timer) {
    const maxTime = 10;
    const strokeLength = 172.78759594743863;
    return strokeLength * (1 - timer / maxTime);
  }

  return (
    <div className="bg-indigo-800 px-4 py-3 sm:rounded-t-lg flex justify-between gap-4">
      {gameResult && (
        <div class="font-bebas-neue text-white text-3xl">{gameResult}</div>
      )}
      {!gameResult && (
        <div>
          <div className="flex items-center gap-3 sm:gap-4">
            <div className="self-start bg-white rounded-full filter-shadow text-center">
              <div
                style={{ position: "relative", width: "60px", height: "60px" }}
              >
                <svg
                  viewBox="0 0 60 60"
                  width="60"
                  height="60"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m 30,2.5 a 27.5,27.5 0 1,0 0,55 a 27.5,27.5 0 1,0 0,-55"
                    fill="none"
                    stroke="transparent"
                    strokeWidth="5"
                  ></path>
                  <path
                    className="timer-circle"
                    d="m 30,2.5 a 27.5,27.5 0 1,0 0,55 a 27.5,27.5 0 1,0 0,-55"
                    fill="none"
                    stroke={getColorBasedOnTime(timer)}
                    strokeLinecap="square"
                    strokeWidth="5"
                    strokeDasharray="172.78759594743863"
                    strokeDashoffset={calculateDashOffset(timer)}
                  ></path>
                </svg>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="flex items-center justify-center rounded-full">
                    <div className="text-3xl mt-0.5 sm:mt-1 font-bebas-neue text-black">
                      {timer}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="relative flex items-center text-white font-bebas-neue">
                <div className="flex flex-col justify-center">
                  <div
                    translate="no"
                    className="opacity-100 translate-x-0 transition duration-500 sm:text-xl leading-none sm:leading-none"
                  >
                    {playerName?.f}
                  </div>
                  <div
                    translate="no"
                    className="opacity-100 translate-x-0 delay-200 transition duration-500 text-3xl leading-none sm:leading-none"
                  >
                    {playerName?.g}
                  </div>
                </div>
              </div>
              {!isWildCardUsed && (
                <WildCard
                  setIsShowToolTip={setIsShowToolTip}
                  isShowToolTip={isShowToolTip}
                  handleWildCard={handleWildCard}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {playersLeft > 1 && !gameResult && (
        <SkipForwardCom
          clickedRemitId={clickedRemitId}
          onSkip={onSkip}
          playersLeft={playersLeft}
        />
      )}
    </div>
  );
};
export default TimerHeader;
