import React, { useState, useEffect, useCallback } from "react";
import FootballBingo from "../footballBingo";
import data from "../../utils/game.json";
import GameHeader from "../GameHeader/GameHeader";
import TimerHeader from "../GameHeader/TimerHeader";
import NoTimerHeader from "../GameHeader/NoTimerHeader";

import PreviousNextButton from "../GameHeader/GameBottom/PreviousNextButton";

import StatisticsModal from "../UI/GameOverModal";

function Game() {
  const [bingoCard, setBingoCard] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [isTimerMode, setIsTimerMode] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [completed, setCompleted] = useState([]);
  const [startGame, setGameStart] = useState(false);
  const [isWildCardUsed, setIsWildCardUsed] = useState(false);
  const [remainingPlayers, setRemainingPlayers] = useState(
    data.gameData.players
  );
  const [clickedRemitId, setClickedRemitId] = React.useState(null);
  const [timer, setTimer] = useState(10);
  const [intervalId, setIntervalId] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [gameResult, setGameResult] = useState("");
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.gameData?.remit) {
      setBingoCard(data.gameData.remit);
      setLoading(false);
    }
  }, []);

  const selectRandomPlayer = useCallback(() => {
    if (remainingPlayers.length === 0) {
      if (selectedPlayers.length === 0) return null;
      setRemainingPlayers(selectedPlayers);
      setSelectedPlayers([]);
    }

    const randomIndex = Math.floor(Math.random() * remainingPlayers.length);
    const selectedPlayer = remainingPlayers[randomIndex];

    setRemainingPlayers((prevPlayers) =>
      prevPlayers.filter((_, index) => index !== randomIndex)
    );
    setSelectedPlayers((prevSelected) => [...prevSelected, selectedPlayer]);

    return selectedPlayer;
  }, [remainingPlayers, selectedPlayers]);

  useEffect(() => {
    // Check if the board is fully filled
    if (completed.length === 16) {
      setGameResult("You win!"); // Player wins if board is full
      setGameOver(true);
      setGameStart(false);
      return; // Exit early since the game is over
    }

    // Check if there are no remaining players
    if (remainingPlayers.length === 0) {
      setGameResult("You lost!"); // Player loses if no players are left
      setGameOver(true);
      setGameStart(false);
    }
  }, [remainingPlayers, completed]);

  const handleRemitClick = (remit) => {
    if (!currentPlayer) return;

    const isValid = validatePlayerForRemit(currentPlayer, remit);
    console.log("isValid", isValid);

    if (isValid) {
      setCompleted((prevCompleted) => [
        ...prevCompleted,
        { remitId: remit.id, player: currentPlayer },
      ]);
      const nextPlayer = selectRandomPlayer();
      setCurrentPlayer(nextPlayer);
      setTimer(10);
    } else {
      const newRemainingPlayers = remainingPlayers.filter(
        (player) => player !== currentPlayer
      );
      setRemainingPlayers(newRemainingPlayers);

      const nextPlayer = selectRandomPlayer();
      setCurrentPlayer(nextPlayer);
      setTimer(10);
      setClickedRemitId(remit.id);

      setTimeout(() => {
        setClickedRemitId(null);
      }, 1000);
    }
  };

  const handleWildCard = () => {
    if (!currentPlayer) return;
    setIsWildCardUsed(true);

    const matchedRemit = bingoCard.find(
      (remit) =>
        !completed.some((entry) => entry.remitId === remit.id) &&
        validatePlayerForRemit(currentPlayer, remit)
    );

    if (matchedRemit) {
      setCompleted((prevCompleted) => [
        ...prevCompleted,
        { remitId: matchedRemit.id, player: currentPlayer, viaWildCard: true },
      ]);
      const nextPlayer = selectRandomPlayer();
      setCurrentPlayer(nextPlayer);
      setTimer(10);
    } else {
      const newRemainingPlayers = remainingPlayers.filter(
        (player) => player.i !== currentPlayer.i
      );
      setRemainingPlayers(newRemainingPlayers);
      const nextPlayer = selectRandomPlayer();
      setCurrentPlayer(nextPlayer);
      setTimer(10);
    }
  };

  const validatePlayerForRemit = (player, remit) => {
    switch (remit.type) {
      case "trophy":
        return Array.isArray(player.a) && player.a.includes(remit.id);
      case "teammate":
        return Array.isArray(player.s) && player.s.includes(remit.id);
      case "team":
        return Array.isArray(player.t) && player.t.includes(remit.id);
      case "country":
        return player.c === remit.id;
      case "manager":
        return (
          Array.isArray(player.m) && player.m && player.m.includes(remit.id)
        );
      case "league":
        return Array.isArray(player.l) && player.l.includes(remit.id);
      default:
        return false;
    }
  };

  const handleTimer = (timer) => {
    setIsTimerMode(timer);
    setShowHeader(false);
    setGameStart(true);
    const player = selectRandomPlayer();
    setCurrentPlayer(player);
  };
  console.log("current player", currentPlayer);

  useEffect(() => {
    if (timer && isTimerMode && !gameOver) {
      if (intervalId) {
        clearInterval(intervalId);
      }

      const id = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(id);
            handleSkip();
            return 10;
          }
          return prevTimer - 1;
        });
      }, 1000);

      setIntervalId(id);

      return () => clearInterval(id);
    } else if (gameOver) {
      clearInterval(intervalId);
    }
  }, [timer, isTimerMode, gameOver]);

  // Function to skip the current player and pick a new one
  const handleSkip = () => {
    if (!remainingPlayers.length) return; // No players left to skip

    const newRemainingPlayers = remainingPlayers.filter(
      (player) => player !== currentPlayer
    );
    setRemainingPlayers(newRemainingPlayers);

    const nextPlayer = selectRandomPlayer();
    setCurrentPlayer(nextPlayer);
    setTimer(10); // Reset timer for the next player
  };

  useEffect(() => {
    const gameState = {
      completed,
      gameResult,
      isTimerMode,
      currentPlayer,
      isWildCardUsed,
      startGame,
      remainingPlayers,
    };
    console.log("Saving game state to localStorage:", gameState);
    if (startGame) {
      localStorage.setItem("gameState", JSON.stringify(gameState));
    }
  }, [
    completed,
    gameResult,
    isTimerMode,
    currentPlayer,
    isWildCardUsed,
    startGame,
    remainingPlayers,
  ]);

  useEffect(() => {
    const savedState = localStorage.getItem("gameState");
    if (savedState) {
      console.log("Loading game state from localStorage:", savedState);
      const parsedState = JSON.parse(savedState);
      setCompleted(parsedState.completed || []);
      setGameResult(parsedState.gameResult || "");
      setIsTimerMode(parsedState.isTimerMode);
      setRemainingPlayers(parsedState.remainingPlayers);
      setCurrentPlayer(parsedState.currentPlayer || null);
      setIsWildCardUsed(parsedState.isWildCardUsed || false);
      setGameStart(parsedState.startGame || false);
    }
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }
  console.log("bingo card", bingoCard);
  return (
    <div className="max-w-[440px] mx-auto">
      <div className="relative">
        {currentPlayer === null && <GameHeader handleTimer={handleTimer} />}

        <>
          {isTimerMode === true && currentPlayer && (
            <TimerHeader
              onSkip={handleSkip}
              playerName={currentPlayer}
              playerNumber={currentPlayer}
              playersLeft={remainingPlayers.length}
              timer={timer}
              handleWildCard={handleWildCard}
              isWildCardUsed={isWildCardUsed}
              clickedRemitId={clickedRemitId}
              gameResult={gameResult}
            />
          )}
          {isTimerMode === false && currentPlayer && (
            <NoTimerHeader
              onSkip={handleSkip}
              currentPlayer={currentPlayer}
              playerNumber={remainingPlayers.length}
              playersLeft={remainingPlayers.length}
              handleWildCard={handleWildCard}
              isWildCardUsed={isWildCardUsed}
              clickedRemitId={clickedRemitId}
              gameResult={gameResult}
            />
          )}
        </>
        <div className="relative">
          <FootballBingo
            bingoCard={bingoCard}
            completed={completed}
            handleRemitClick={handleRemitClick}
            startGame={startGame}
            clickedRemitId={clickedRemitId}
          />
        </div>
        <div className="mt-4">
          <PreviousNextButton
            currentGame="436"
            onNext={() => console.log("next")}
            onPrevious={() => console.log("previous")}
          />
        </div>
        <StatisticsModal
          open={gameOver}
          gameResult={gameResult}
          onClose={() => setGameOver(false)}
        />
      </div>
    </div>
  );
}

export default Game;
