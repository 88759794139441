import React, { useState, useEffect } from "react";
import { SkipForward } from "lucide-react";

const SkipForwardCom = ({ onSkip, clickedRemitId, playersLeft }) => {
  const [showNotification, setShowNotification] = useState(0);

  useEffect(() => {
    if (clickedRemitId) setShowNotification((pre) => pre + 1);
  }, [clickedRemitId]);

  return (
    <div className="flex flex-col items-end justify-center">
      <button
        className="flex gap-2 text-white items-center justify-center"
        onClick={onSkip}
      >
        <span className="text-lg font-bold">SKIP</span>
        <SkipForward width={20} height={20} />
      </button>
      <div className="relative text-sm sm:text-base opacity-50 leading-none mt-1 font-bebas-neue text-white">
        {playersLeft - 1} Players Left
        <div key={showNotification} className="notification-popup">
          -1
        </div>
      </div>
    </div>
  );
};

export default SkipForwardCom;
