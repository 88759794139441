import React from "react";
import Button from "@mui/material/Button";
import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
} from "lucide-react";

function GameNavigation({ currentGame, firstGame, lastGame }) {
  return (
    <div className="flex justify-between items-center gap-6 uppercase text-xs font-bold relative -m-2 text-white">
      <div className="flex-1 flex items-center justify-start">
        <Button
          asChild
          variant="ghost"
          size="icon"
          title="Play the first available game"
        >
          <ChevronsLeft className="h-5 w-5" color="#ceff27" />
          <span className="sr-only">First Game</span>
        </Button>
        <Button asChild variant="ghost">
          <ChevronLeft className="h-5 w-5" color="#ceff27" />
          <span className="truncate font-bebas-neue">Previous</span>
        </Button>
      </div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center">
        <span className="opacity-60">#{currentGame}</span>
      </div>
      <div className="flex-1 flex items-center justify-end">
        <Button asChild variant="ghost">
          <span className="truncate font-bebas-neue">Next</span>
          <ChevronRight className="h-5 w-5" color="#ceff27" />
        </Button>
        <Button asChild variant="ghost" size="icon" title="Play today's game">
          <ChevronsRight className="h-5 w-5" color="#ceff27" />
          <span className="sr-only">Latest Game</span>
        </Button>
      </div>
    </div>
  );
}

export default GameNavigation;
