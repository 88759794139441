import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
const StatisticsModal = ({ open, onClose, gameResult }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 3, backgroundColor: "#222", color: "#fff" },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Statistics
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <div className="text-2xl my-4 text-center font-bebas-neue">
          {gameResult}
        </div>

        {/* Statistics */}
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "baseline",
          }}
        >
          <Stack direction={"column"}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1 }}>
              2
            </Typography>
            <Typography variant="subtitle2" color="gray">
              Total Tries
            </Typography>
          </Stack>
          <Stack direction={"column"}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1 }}>
              0
            </Typography>
            <Typography variant="subtitle2" color="gray">
              Streak
            </Typography>
          </Stack>
          <Stack direction={"column"}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1 }}>
              0
            </Typography>
            <Typography variant="subtitle2" color="gray">
              Best Streak
            </Typography>
          </Stack>
        </Box>
        <Stack
          direction={"column"}
          className="flex justify-center items-center"
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
            0%
          </Typography>
          <Typography variant="subtitle2" color="gray">
            Success Rate
          </Typography>
        </Stack>
        {/* Countdown */}
        <Box className="mt-6 sm:mt-8 p-4 sm:px-6 sm:py-8 -mx-4 sm:-mx-6 bg-white/20 text-center">
          <span className="font-bebas-neue text-xl text-white">
            NEXT FOOTBALL BINGO IN 11:16:28
          </span>

          <div class="flex justify-between pt-2 space-x-2">
            <div class="flex-1 flex">
              <button
                type="button"
                class="flex-1 font-bold flex items-center justify-center text-sm sm:text-base rounded-md border border-transparent text-center shadow-sm p-3 focus:outline-none focus:ring-2 focus:ring-offset-2 text-black bg-[#ceff27] hover:bg-[#ceff27]/90 focus:ring-[#ceff27]"
              >
                <span class="block tracking-wide font-bold leading-none">
                  Reveal Answers*
                </span>
              </button>
            </div>
            <div class="flex-1 flex">
              <button
                type="button"
                class="flex-1 flex items-center justify-center rounded-md border border-transparent text-center shadow-sm px-6 py-3 bg-indigo-600 text-sm sm:text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                tabindex="0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  class="w-5 h-5 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                  ></path>
                </svg>
                <span class="block tracking-wide font-bold leading-none">
                  Share
                </span>
              </button>
            </div>
          </div>
        </Box>

        {/* Footer */}
        <div className="mt-4 text-center flex flex-col justify-center items-center mb-16">
          <span className="font-bebas-neue">
            Can you top the Football Bingo Leaderboard?
          </span>
          <span>Follow our stories on Instagram to compete</span>
          <Button
            variant="contained"
            sx={{
              mt: 1,
              textTransform: "none",
              background: "linear-gradient(to right, #ff512f, #dd2476)",
              fontWeight: "bold",
              //   width: "50%",
            }}
          >
            @playfootball.games
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StatisticsModal;
