import React from "react";

import BingoCard from "./BingoCard";

const BingoBoard = ({
  bingoCard,
  handleRemitClick,
  completed,
  startGame,
  clickedRemitId,
}) => {
  return (
    <div className="grid grid-cols-[repeat(4,minmax(90px,110px))] grid-rows-[repeat(4,minmax(90px,110px))] w-full aspect-square bg-[#4b3d59]">
      {bingoCard.map((remit, index) => (
        <BingoCard
          key={index}
          index={index}
          remit={remit}
          completed={completed}
          handleRemitClick={handleRemitClick}
          matchedRemitId={
            completed.find((c) => c.remitId === remit.id)?.remitId
          }
          playerName={completed.find((c) => c.remitId === remit.id)?.player}
          startGame={startGame}
          clickedRemitId={clickedRemitId}
        />
      ))}
    </div>
  );
};

export default BingoBoard;
